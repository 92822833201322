import ChartBuffer from './ChartBuffer';

if (!window.Chartkick) {
  const Chartkick = {
    isChartsBuffer: true,
  };

  const chartNames = [
    'AreaChart',
    'BarChart',
    'BubbleChart',
    'ColumnChart',
    'GeoChart',
    'LineChart',
    'PieChart',
    'ScatterChart',
    'Timeline',
  ];

  chartNames.forEach(chartName => {
    Chartkick[chartName] = class extends ChartBuffer {
      static buffer = [];
    };
  });

  window.Chartkick = Chartkick;
}
